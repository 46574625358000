import React from 'react';
import footerLogo from 'assets/images/logos/estela.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PiInstagramLogoFill } from 'react-icons/pi';

export default function MainFooter() {
    const { t } = useTranslation();

    return (
        <footer className="nv__footer">
            <section className="nv__section__container">
                <div className="nv__footer__block nv__footer__block--big">
                    <div className="nv__footer__block__item"></div>

                    <div className="nv__footer__block__item">
                        <p>
                            info@agrodonastella.com
                            <br />
                            +507 6992 7878
                        </p>
                    </div>

                    <div className="nv__footer__block__item">
                        <Link
                            to="https://www.instagram.com/agrodonastella?igsh=MWp6ZnF5OTY4ZGxzZg%3D%3D"
                            target="_blank"
                        >
                            <p>
                                <PiInstagramLogoFill /> Instagram
                            </p>
                        </Link>
                    </div>
                    <div className="nv__footer__block__item nv__footer__block__item--web">
                        <div className="nv__footer__logo">
                            <a href="#">
                                <img src={footerLogo} alt="Logo Narval white blue" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="nv__footer__logo nv__footer__logo--mobile">
                    <a href="#">
                        <img src={footerLogo} alt="Logo Narval white blue" />
                    </a>
                </div>
            </section>
        </footer>
    );
}
